import {BaseQueryFn, createApi, retry} from '@reduxjs/toolkit/query/react'
import {AxiosError, AxiosRequestConfig} from "axios";
import {HYDRATE} from 'next-redux-wrapper'
import axiosApiInstance from "../utils/axios";

const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<
{
  url: string
  method?: AxiosRequestConfig['method']
  body?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers']
},
unknown,
unknown
> =>
async ({ url, method, body, params, headers }) => {
 try {
    const result = await axiosApiInstance({ url: baseUrl + url, method, data: body, params, headers })
    return { data: result.data }
  } catch (axiosError) {
    let err = axiosError as AxiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    }
  }
}

const baseQuery = retry(axiosBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_MYSTES_API_URL ?? 'http://127.0.0.1:80',
}), {maxRetries: 5})

export const mystesApi = createApi({
  baseQuery: baseQuery,
  reducerPath: 'mystesApi',
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: () => ({}),
})

export const nodeApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: 'http://localhost:3000/api/',
  }),
  keepUnusedDataFor: 300,
  reducerPath: 'nodeApi',
  endpoints: () => ({}),
})